/* Generated by Glyphter (http://www.glyphter.com) on  Sun Mar 03 2019*/
@font-face {
    font-family: 'IconFont';
    src: url('../fonts/icons.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'IconFont';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-play:before{content:'\0041';}
.icon-snail:before{content:'\0042';}
.icon-stop:before{content:'\0043';}
.icon-text-style:before{content:'\0044';}
.icon-times:before{content:'\0045';}
.icon-brush:before{content:'\0046';}
.icon-check:before{content:'\0047';}
.icon-film:before{content:'\0048';}
.icon-font:before{content:'\0049';}
.icon-pause:before{content:'\004a';}