html, body, #root, .App{
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

#editor-canvas-container{
  border-radius: 9px;
  overflow: hidden;
}