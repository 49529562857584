body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rc-slider-rail{
    height: 15px;
    border-radius: 15px;
    background-color: #121212;
}

.rc-slider-track{
    height: 15px;
    border-radius: 15px;
    background-color: #B2B2B2;
}

.rc-slider-handle{
    width: 30px;
    height: 30px;
    background-color: rgb(252, 61, 74);
    border: 3px solid rgb(255, 255, 255);
    margin-top: -8px;
    margin-left: -15px;
    border-color: #fff !important;
}

.rc-slider-handle:hover{
    border-color: #fff;
}

.rc-slider-handle:focus:{
    box-shadow: none;
    border-color: #fff;
}
/* Generated by Glyphter (http://www.glyphter.com) on  Sun Mar 03 2019*/
@font-face {
    font-family: 'IconFont';
    src: url(/static/media/icons.12c5c428.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'IconFont';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-play:before{content:'A';}
.icon-snail:before{content:'B';}
.icon-stop:before{content:'C';}
.icon-text-style:before{content:'D';}
.icon-times:before{content:'E';}
.icon-brush:before{content:'F';}
.icon-check:before{content:'G';}
.icon-film:before{content:'H';}
.icon-font:before{content:'I';}
.icon-pause:before{content:'J';}
html, body, #root, .App{
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

#editor-canvas-container{
  border-radius: 9px;
  overflow: hidden;
}
/* @font-face {
    font-family: 'HelveticaCaps';
    src: url('HelveticaNeueLTGEOCaps-45Light.woff2') format('woff2'),
        url('HelveticaNeueLTGEOCaps-45Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
} */

@font-face {
    font-family: 'HelveticaCaps';
    src: url(/static/media/HelveticaNeueLTGEOCaps-55Roman.df0c9b88.woff2) format('woff2'),
        url(/static/media/HelveticaNeueLTGEOCaps-55Roman.dd38e041.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

/* @font-face {
    font-family: 'HelveticaCaps';
    src: url('HelveticaNeueLTGEOCaps-75Bold.woff2') format('woff2'),
        url('HelveticaNeueLTGEOCaps-75Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
} */

/* @font-face {
    font-family: 'Helvetica';
    src: url('HelveticaNeueLTGEO-45Light.woff2') format('woff2'),
        url('HelveticaNeueLTGEO-45Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
} */

@font-face {
    font-family: 'Helvetica';
    src: url(/static/media/HelveticaNeueLTGEO-55Roman.d86ee556.woff2) format('woff2'),
        url(/static/media/HelveticaNeueLTGEO-55Roman.0032fbba.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

/* @font-face {
    font-family: 'Helvetica';
    src: url('HelveticaNeueLTGEO-75Bold.woff2') format('woff2'),
        url('HelveticaNeueLTGEO-75Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
} */

@font-face {
    font-family: 'Banner';
    src: url(/static/media/BPGBannerCaps.f927f359.woff2) format('woff2'),
        url(/static/media/BPGBannerCaps.b9f4f3b5.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}


