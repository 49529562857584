.rc-slider-rail{
    height: 15px;
    border-radius: 15px;
    background-color: #121212;
}

.rc-slider-track{
    height: 15px;
    border-radius: 15px;
    background-color: #B2B2B2;
}

.rc-slider-handle{
    width: 30px;
    height: 30px;
    background-color: rgb(252, 61, 74);
    border: 3px solid rgb(255, 255, 255);
    margin-top: -8px;
    margin-left: -15px;
    border-color: #fff !important;
}

.rc-slider-handle:hover{
    border-color: #fff;
}

.rc-slider-handle:focus:{
    box-shadow: none;
    border-color: #fff;
}