/* @font-face {
    font-family: 'HelveticaCaps';
    src: url('HelveticaNeueLTGEOCaps-45Light.woff2') format('woff2'),
        url('HelveticaNeueLTGEOCaps-45Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
} */

@font-face {
    font-family: 'HelveticaCaps';
    src: url('HelveticaNeueLTGEOCaps-55Roman.woff2') format('woff2'),
        url('HelveticaNeueLTGEOCaps-55Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* @font-face {
    font-family: 'HelveticaCaps';
    src: url('HelveticaNeueLTGEOCaps-75Bold.woff2') format('woff2'),
        url('HelveticaNeueLTGEOCaps-75Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
} */

/* @font-face {
    font-family: 'Helvetica';
    src: url('HelveticaNeueLTGEO-45Light.woff2') format('woff2'),
        url('HelveticaNeueLTGEO-45Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
} */

@font-face {
    font-family: 'Helvetica';
    src: url('HelveticaNeueLTGEO-55Roman.woff2') format('woff2'),
        url('HelveticaNeueLTGEO-55Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* @font-face {
    font-family: 'Helvetica';
    src: url('HelveticaNeueLTGEO-75Bold.woff2') format('woff2'),
        url('HelveticaNeueLTGEO-75Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
} */

@font-face {
    font-family: 'Banner';
    src: url('BPGBannerCaps.woff2') format('woff2'),
        url('BPGBannerCaps.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

